.ayah{
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-family: "Amiri Quran", serif;
  color: var(--border-rose-color-hover);;
  &.dark{
    background-color: var(--background-dark);
    color: var(--light);
  }
  &.rose{
    background-color: var(--background-rose);
    color: var(--dark);
  }
}