.what_to_read {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  transition: var(--main-transition);
  backdrop-filter: blur(6px);
  display: none;
  opacity: 1;
  &.block {
    display: block;
    animation: opacityAnimation 1s;
  }
  &.dark {
    background: #1f2125b8;
    .text_read {
      background: var(--background-dark);
      box-shadow: 0 0 5px var(--background-second-dark);
      .search {
        border-bottom-color: var(--background-second-dark);
        input {
          color: var(--background-second-dark);
          &::placeholder {
            color: var(--background-second-dark);
          }
        }
        .icon {
          i {
            color: var(--background-second-dark);
          }
        }
      }
      .search_result {
        .default {
          ul {
            li {
              a {
                color: var(--border-dark-surah);
                &:hover {
                  background-color: var(--background-second-dark);
                  color: var(--light);
                }
              }
            }
          }
        }
      }
    }
  }
  &.rose {
    background-color: #f8ebd594;
    .text_read {
      background-color: var(--rose);
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .text_read {
    background-color: var(--light);
    border-radius: 5px;
    width: 650px;
    box-shadow: 0 0 10px #938d8d;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px; 
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c8c7c7; 
      border-radius: 6px; 
    }
    .search {
      display: flex;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid var(--border-color);
      input {
        outline: 0;
        border: 0;
        width: 500px;
        direction: rtl;
        padding: 10px;
        font-size: 25px;
        color: var(--color-text-button);
        background-color: inherit;
        &::placeholder {
          color: #d4d4d4;
        }
      }
      .icon {
        i {
          font-size: 20px;
          color: var(--border-color);
        }
      }
    }
    .search_result {
      .default {
        ul {
          direction: rtl;
          h4 {
            padding: 10px 20px;
            font-size: 15px;
            color: var(--border-dark-surah);
          }
          li {
            a {
              display: flex;
              align-items: center;
              justify-content: end;
              gap: 10px;
              direction: ltr;
              padding: 10px 20px;
              font-size: 14px;
              border-radius: 10px;
              transition: var(--main-transition);
              &:hover {
                background-color: var(--border-color);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .what_to_read {
    .text_read {
      width: 500px;
    }
  }
}

@media (max-width: 550px) {
  .what_to_read {
    .text_read {
      width: 400px;
      .search {
        input {
          width: 200px;
          flex: 1;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .what_to_read {
    .text_read {
      width: 300px;
      .search {
        input {
          width: 200px;
          flex: 1;
        }
      }
    }
  }
}
