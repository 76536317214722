.radio {
  position: relative;
  &.dark{
    background-color: var(--background-dark);
    h3{
      color: var(--light);
    }
    .bg{
      background-color: var(--background-second-dark);
    }
    .radio_content{
      .auto_audio{
        .all_boxs{
          .box{
            .box_text{
              color: var(--light);
              background-color: var(--background-second-dark);
            }
          }
        }
      }
    }
    .readers{
      .readers_container{
        .reader{
          .name{
            background-color: var(--border-dark-surah);
            color: var(--light);
          }
        }
      }
    }
  }
  &.rose{
    background-color: var(--border-rose-color);
    h3{
      color: var(--light);
    }
    .bg{
      background-color: var(--border-rose-color-hover);
    }
    .radio_content{
      .auto_audio{
        .all_boxs{
          .box{
            .box_text{
              color: var(--light);
              background-color: var(--border-rose-color-hover);
            }
          }
        }
      }
    }
    .readers{
      .readers_container{
        .reader{
          .name{
            background-color: var(--border-rose-color-hover);
            color: var(--light);
          }
        }
      }
    }
  }

  h2 {
    direction: rtl;
    color: var(--light);
  }
  .bg {
    background-color: #2ca4ab;
    width: 100%;
    height: 250px;
  }
  .radio_content {
    .auto_audio {
      margin-top: -160px;
      .all_boxs {
        overflow-x: scroll;
        display: flex;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: nowrap;
        direction: rtl;
        &::-webkit-scrollbar {
          height: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-secondary-bgimg);
          border-radius: 10px;
        }
        .box {
          width: 300px !important;
          display: block;
          direction: ltr;
          .img {
            width: 300px;
            height: 250px;
            position: relative;
            &:hover {
              .play {
                z-index: 2;
              }
            }
            .play {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px 10px 0 0;
              cursor: pointer;
              font-size: 30px;
              color: var(--border-color);
              background: #464b5063;
              transition: var(--main-transition);
              z-index: -1;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px 10px 0 0;
            }
          }
          .box_text {
            background-color: #ebeef052;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            .icon {
              cursor: pointer;
              color: var(--color-secondary-bgimg);
            }
            h3 {
              font-size: 18px;
              font-weight: normal;
            }
            p {
              color: var(--color-secondary-bgimg);
            }
          }
        }
      }
    }
  }
}
