.azkar {
  padding-top: 50px;
  padding-bottom: 50px;
  &.dark{
    background-color: var(--background-dark);
    .categories{
      border-color: var(--border-dark-surah);
      .zek_name{
        background-color: var(--background-second-dark);
        color: var(--light);
        &:hover{
          background-color: var(--dark);
        }
      }
    }
  }
  &.rose{
    background-color: var(--background-rose);
    .categories{
      border-color: var(--border-rose-color);
      .zek_name{
        background-color: var(--border-rose-color);
        &:hover{
          background-color: var(--border-rose-color-hover);
        }
      }
    }
  }
  .categories {
    display: flex;
    direction: rtl;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    gap: 10px;
    .zek_name {
      background-color: var(--border-color);
      padding: 20px 10px;
      border-radius: 5px;
      font-size: 22px;
      cursor: pointer;
      font-family: "Amiri Quran";
      line-height: 1.5;
      padding-top: 0;
      transition: var(--main-transition);
      width: 200px;
      text-align: center;
      &:hover {
        background-color: var(--color-secondary-bgimg);
        color: var(--light);
      }
    }
  }
  .praying_azkar {
    text-align: center;
    font-family: "Amiri Quran", serif;

    &.dark {
      background-color: var(--background-dark);
      h1 {
        border-color: var(--border-dark-surah);
        color: var(--border-color);
        background: #464b5066;
      }
      .zekr {
        border: 2px solid var(--border-dark-surah);
        background: var(--background-second-dark);
        h2 {
          color: var(--border-color);
        }
        p {
          color: var(--color-secondary-bgimg);
        }
        h1 {
          &.name {
            background-color: var(--background-dark);
          }
        }
        span{
          color: var(--background-dark);
        }
      }
    }
    &.rose {
      background-color: var(--background-rose);
      border-color: var(--border-rose-color-hover);
      h1 {
        background: #72603f73;
        color: var(--border-color);
        border-color: var(--border-rose-color);
        
      }
      .zekr {
        color: var(--dark);
        border-color: var(--border-rose-color);
        background-color: #c7ac84;
        h1{
          &.name{
            background-color: var(--border-rose-color-hover);
          }  
        }
        span{
          color: var(--border-rose-color-hover);
        }
      }
    } 
    h1 {
      text-align: center;
      margin: 20px 0;
      padding: 20px;
      border: 2px solid var(--border-color);
      border-radius: 10px;
      transition: var(--main-transition);
      font-weight: normal;
      padding-top: 10px;
      background: #2e8c925e;
      color: var(--border-dark-surah);
      margin-bottom: 50px;
      font-family: system-ui;
      &.name {
        font-size: 25px;
        margin: 0;
        width: 100%;
        font-family: "Amiri Quran";
        line-height: 1;
        background-color: var(--color-secondary-bgimg);
        color: var(--light);
        border: none;
      }
    }
    .zekr {
      text-align: center;
      margin: 20px 0;
      padding: 20px;
      border: 2px solid var(--border-color);
      border-radius: 10px;
      transition: var(--main-transition);
      display: flex;
      justify-content: center;
      direction: rtl;
      flex-direction: column;
      border-color: var(--color-secondary-bgimg);
      background-color: #f4fffe;
      align-items: start;
      h2 {
        font-weight: normal;
        direction: rtl;
        text-align: right;

        &.doaaa {
          text-align: right;
        }
      }
      p {
        font-weight: normal;
        font-size: 24px;
        color: var(--border-dark-surah);
        // margin-top: -20px;
      }
      h4 {
        font-weight: normal;
        font-family: "Noto Naskh Arabic";
        font-size: 17px;
        color: var(--color-secondary-bgimg);
      }
      span {
        color: var(--swiper-theme-color);
        position: relative;
        top: 5px;
      }
    }
  }
}

@media (max-width: 710px) {
  .azkar {
    .categories {
      .zek_name {
        width: calc(100% / 2 - 10px);
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 425px) {
  .azkar {
    .categories {
      .zek_name {
        width: 100%;
      }
    }
    .praying_azkar {
      .zekr {
        h1 {
          font-size: 30px;
        }
        h2 {
          font-size: 20px;
          line-height: 1.7;
        }
        p {
          font-size: 18px;
          margin-top: 0;
        }
      }
    }
  }
}
